.property-content{
  margin-top:30px;
  padding-bottom:20px;
  background: #f6f6f6;
  position:relative;

  .favorite-icon i{
    font-size: 25px;
    margin-top:0;
  }
}
.property-cover{
  position: relative;
  min-height: 300px;
  overflow:hidden;
  margin-bottom:30px;

  .property-cover-image{
    position: absolute;
    top:-20px;left:-20px;right:-20px;bottom:-20px;
    background-size: cover;
    background-position: center center;
    -webkit-filter: blur(15px); /* Safari 6.0 - 9.0 */
    filter: blur(15px);
  }
}

.property-show-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  .property-show-header-title{
    h1{
      max-width:450px;
      font-size:30px;
      font-weight: 700;
    }
  }
}

.favicon-property-show{
  margin-left:20px;
  float:right;

  i{
    font-size: 18px;
    width: 50px;
    height: 50px;
    margin-bottom: 5px;
    border-radius: 50%;
    background-color: #111111;
    color: #fff;
    line-height: 50px;
    text-align: center;
  }
}
.property-show-header-meta, .property-show-header-title{
  position:relative;
  padding-right:20px;
  margin-right:20px;

  &:after{
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    content: "";
    width: 1px;
    height: 80px;
    background-color: #ddd;
  }
}
.property-cover-info{
  color: #fff;
  position: relative;
  margin-top: 50px;

  h1,h2,h3,h4,a{
    color: #fff;
  }
  .property-show-zone{
    padding: 6px 15px;
    border: 1px dashed #D9D9D9;
  }

}


.property-delimiter-box{
  //border-radius: 5px;
  background-color: #fff;
  padding:10px 30px 20px 30px ;
  margin-top: 30px;
  &:hover{
    box-shadow: 0 0 7px 0 rgba(0,0,0,.15);
  }

  //border: 1px solid #eee;
}

.divider-header-property{
  padding: 10px 0 !important;
}

.property-wrapper{
  //padding:30px 0;
  //margin-top: 30px;


  .container{
    //background: #fff;
    //padding: 30px;
  }

  .property-content{
    // background: #fff;
    // padding: 30px;
    h2,h3,h4,h5{
      text-align:left;
    }

    h2.page-header{
      margin:0 0 25px 0;
      border-bottom: 1px solid #eee;
      font-size:20px;
    }
//
//    h2{
//      color:#000;
//      font-size: 18px;
//      &:after{
//        display: none;
//      }
//      .first-word{
//        color:#3a3f4c;
//      }
    //}
  }




}


.property-aside-content{
  //background: #fff;
  // padding: 30px;
}

.property-content-gallery{
  margin: -30px -30px 0 -30px;
}


.property-main-gallery{
  .property-main-gallery-item{
    text-align:center;
    img{
      max-width: 100%;
      height: auto;
      max-height: 740px;
      display: block;
      vertical-align: middle;
      text-align: center;
      margin:0 auto;
    }
  }
}

.property-show-price{
  font-weight:700;
  //margin-top:5px;
  display: block;
  // text-align: right;
  font-size:24px;
  color: @brand-color;
  //float:right;
}

.property-show-short-caracteristics{
  margin-top: 30px;
  text-align: center;
  float:left;
  margin-right: 20px;

  .box-short{
    i,i:before{
      font-size:40px;
      // display:block;
    }

    .box-short-title{
      font-weight: bold;
      display: block;
    }
  }
}

.property-show-lista-tabelara{
  margin:0;
  padding:0;
  li{
    padding:0;
    list-style:none;
    border-bottom: 1px solid #f7f7f7;

    span{
      float: right;
      font-weight: bold;
    }
  }
}
.property-show-specs ul{
  list-style:none;
  .fa{
    opacity:.5;
  }
  li span{
    font-weight: bold;
  }
}

#window-map{
  height: 350px;
}

.property-show-contact-short{
  //  background:#1565C0;
  // padding: 15px;
  .agent-small-content{
    border-bottom: none;
  }
}

.agent-contact-phone{
  clear:both;
  background:@brand-color;
  padding: 15px;
  color: #fff;
  font-size: 18px;
  display: block;
  text-align: center;
  margin-top: 15px;
}

.property-show-meta-citem{
  // display: table-cell;
  display:inline-block;
  color: #515666;
  font-size: 12px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  //padding:5px 10px;
  font-weight: 400;
  margin-right:20px;
  // border-right:  1px solid #ebebeb;
  span{
    display:block;
    font-size:24px;
  }
  &:last-child{
    border-right:none;
  }
  i{

    color: #BBBBBB;
    margin-right: 2px;
    //display: inline-block;
    display:none;
    width: 20px;
    line-height: 20px;
    font-size: 14px;
    &:before{
      font-size: 14px;
    }
  }
  .fa {
    opacity: .6;
  }
}

.contact-form{
  margin-top:30px;


  textarea{
    min-height: 120px;
  }
  .btn{
    width: 100%;
    color: #fff;
    background:@brand-color;
    padding: 15px 30px;
    border-radius: 5px;
  }
}


.property-show-contact-vertical{
  border: 1px solid #EEEEEE;
  padding: 30px;
  // background: #FAFAFA;
}

.btn-print{
  width: 100%;
}

.property-show-title{
  font-size: 30px;
  margin: 5px 0 30px 0;
  padding:0;
  font-weight: normal;

}

.property-show-zone{
  margin-top:0;
  font-size: 16px;
  color: #666666;

}

.property-show-id{
  // font-weight: bold;
  clear:both;
  background:@brand-color;
  border-radius: 5px;
  font-weight: 500;
  padding: 3px 10px;
  color: #fff;
}


.property-show-visits{
  // padding-left: 20px;
}

.property-main-gallery-item{
  img.img-portrait{
    width: auto !important;
  }
}

.property-content .label-wrapper{
  top:-20px;
}

.property-show-specs h5{
  font-size: 16px;
  color:#111;
}

.property-aside-content .property-delimiter-box{
  border: 1px solid #f0f0f0;
  padding: 0 15px 15px 15px;
}

.property-show-box, .property-show-description{
  margin-bottom: 30px;
}

.property-short-caracteristics{
  margin: 30px 0;
  display: table;
  width: 100%;

  .property-box-shortc{
    display: table-cell;
    vertical-align: middle;

    span{
      padding-right: 10px;
    }

    b{
      font-size: 24px;
    }
  }
}

.slick-main{
  &:hover{
    .slick-prev, .slick-next{
      opacity:1;
    }
  }

  .slick-prev, .slick-next {
    display:block;
    background: #000;
    width:50px;
    height:50px;
    opacity: 0;

    z-index:10;
    &:before{
      font-family: "FontAwesome";
      color: #fff;
      font-size:24px;
    }
  }
  .slick-next {
    right:0;
    &:before{
      content:"\f105";
    }
  }
  .slick-prev {
    left:0;
    &:before{
      content:"\f104";
    }
  }
}
.slick-left{
  width:10%;
  float:left;
//  background:#f6f6f6;
 // padding:20px 0;

  .slick-prev, .slick-next {
    left: 50%;
    //transform: translate(-50%, 0) rotate(90deg);
    transform: translate(-50%, 0);
    z-index:10;

    &:before{
      font-family: "FontAwesome";
      color: #333;
    }
  }
  .slick-next {
    top: unset;
    bottom:0;
    background: rgba(255,255,255,.3);
    &:before{
      content:"\f107";
    }
  }
  .slick-prev {
    top: 0;
    z-index: 100;
    background: rgba(255,255,255,.3);
    &:before{
      content:"\f106";
    }
  }

  .property-paging-item.slick-slide{
    //border:3px solid transparent;
  }
  .property-paging-item.slick-current{
    //border:3px solid @brand-color;
    opacity:.7;
  }
  .property-paging-item{
    position:relative;
    min-height:1px;
    padding-bottom: 81px;
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      max-width: none;
      height: 100%;
      width: auto;
      display:block;
    }
  }

}

.slick-slide{
  outline: none !important;

  &:focus, a {
    outline: none;
  }
}
.slick-main{
  width:90%;
  float:left;
 // background:#f6f6f6;
}

@media (max-width: 1024px) {
  .slick-left, .slick-main{
    width:100%;
    padding:0 20px;
    //display:block;
    float:none;

  }


  .slick-left{
    height:85px;

    .property-paging-item{
      img{
        height:auto;
        width:100%;
      }
    }
    .slick-prev, .slick-next {
     transform:rotate(-90deg);
      top:unset;
      bottom:unset;
    }

    .slick-prev{
      left:-20px;
      top:50%;
    }

    .slick-next{
      right:-20px;
      left:unset;
      top:50%;
    }
  }



}

@media (max-width: 767px) {
  .property-show-header-meta, .property-show-header-title{
    &:after{
      display:none;
    }
  }
  .property-show-header-meta{
    margin-top:20px;
  }
  .property-show-header{
    display:block;
  }
  .property-short-caracteristics{
    .property-box-shortc{
      span{
        display: block;
      }
    }

  }
}

.favicon-property-show{
  float:right;
  margin-right: 10px;
  .favorite-icon{
    color: #666;
  }
}