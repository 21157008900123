.form-control{
  box-shadow: none;
  -webkit-box-shadow: none;
  border: 1px solid #d9e0e4;
}

.has-error{
  .help-block{
    font-size: 13px;
    color: orangered;
  }
}

.alert-danger{
  background: red;
  color: #fff;
  .close{
    color: #fff;
    opacity: .8;
  }
}

.has-error .form-control{
  border: 1px solid red;
  //border-color: #a94442;
}

form .loader-wrapper{
  //background-color: rgba(255,255,255,0.8);
}

.topquicksearch{
  input{
    width: 100px;
    padding: 2px 5px;
    height: 30px;
  }
}

.ofertaform, .requestform{
  margin-bottom: 50px;
}

label.has-error {
  color: red !important;
}

.search-form-wrapper .search-inner {
.btn-primary{
 // height: 45px;
  padding: 10px 30px;

  margin:0;
  font-size: 14px;
}
  button{
    //height: 45px;
  }
  input.form-control{
    padding: 8px 15px;
    height: 38px;
  }

  button.btn{
    padding: 8px 15px;
  }
}

