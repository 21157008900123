.testimonials-wrapper{
  border: 1px solid #eee;
  padding:50px;
  margin: 0 0;
  position:relative;
  h2{
    margin-top:0;
  }

  .testimonials-icon{
    position: absolute;
    z-index: 2;
    top: -30px;
    width: 58px;
    height: 58px;
    border: 1px solid #eee;
    background-color: #fff;
    border-radius: 50%;
    text-align: center;

    i{
      line-height: 58px;
      font-size: 30px;
      color: @brand-color;
    }
  }
}

.testimonial-slider-item{

  margin:0 auto;
  position:relative;
  margin-bottom: 30px;
  padding-bottom: 30px;
  padding-left:40px;

  .testimonial-slider-item-content{
    font-size: 16px;
    font-weight: 300;
    float:left;
    display: block;
    overflow: hidden;
    // width:70%;
  }

  .testimonial-slider-item-image{
    display: block;
    float:left;
    margin-right:30px;
    max-width: 150px;
    border-radius:50%;
    overflow: hidden;
    // width:20%;
  }
  .testimonial-slider-item-author{

    display:block;
    font-size: 16px;
    font-weight: 600;
  }
}


.testimonials-wrapper{
  width:100%;
  //margin-top:20px;
}
.testimonial-item{

  margin:0 auto;
  position:relative;
  margin-bottom: 20px;

  .testimonial-item-content{
    font-size: 16px;
    font-weight: 300;
    display: block;
    overflow: hidden;
    font-style: italic;
    // width:70%;
  }

  .testimonial-item-image{
    display: block;
    float:right;
    margin-right:15px;
    max-width: 60px;
    border-radius:50%;
    overflow: hidden;
    margin-top:-20px;
    // width:20%;
  }
  .testimonial-item-author{

    display:block;
    float:right;
    margin-top:10px;
    font-size: 18px;
    font-weight: 300;
  }
}