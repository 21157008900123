a.mobile-contact{
  border-radius: 5px;
  bottom: 50px;
  height: 40px;
  line-height: 40px;
  position: fixed;
  width: 140px;
  z-index: 999;
  font-size: 20px;
  color: #fff;
  text-align: center;

  span{
    font-size: 16px;
    font-weight: bold;
  }
}

a.contact-agent-phone {
  background: #CD2226;
  left: 20px;
}

a.contact-agent-whatsapp {
  background: #25D366;
  right: 20px;
}

