
::placeholder{
  color: #999;
}


.btn-advsearch {

  background-color: @mycolor-orange !important;

  .show-on-collapsed {
    display: none;
  }

  .hide-on-normal{
    display:inline-block;
  }

  &.collapsed {
    .show-on-collapsed {
      display: inline;
    }

    .hide-on-normal{
      display: none;
    }
  }
}

.search-form-wrapper{
.btn-block{
  margin-top:25px !important;
}
  //background:rgba(255,255,255,.6);
  //background:rgba(0,0,0,.3);
  //background: #fff;
  //background: #fff;
  //  padding: 15px 15px 0 15px;

  label{
    color:#fff;
    font-size:12px;
    text-transform: uppercase;
    font-weight: 200;
  }

  .form-group{
    margin-bottom:10px;
  }
  .btn-primary{
    background: @brand-color;
    border:none;

    &:active{
      background: darken(@brand-color, 10%);
    }
  }
}

.search-form-vertical{
  .btn-primary{
    background: @mycolor-red;
    border:none;

    &:active{
      background: darken(@mycolor-red, 10%);
    }
  }
}


@media(max-width: @screen-sm) {
  .half-splash {
    .search-form-wrapper {
      margin: 15px auto 15px;
      width: 100%;
      padding: 20px;
    }

    .short-location{
      width: 70%;
      float:left;
    }
  }
}

.search-inner .input-short{
  height: 64px;
  border-radius:0;
  button{
    line-height: 40px;
    border-radius:0;

    .filter-option{
      padding-right: 10px;
    }
  }
}
.search-inner .input-red{
  &.form-control{
    background: @mycolor-red;
  }

  &.open .dropdown-toggle.btn-default:hover{
    background: none !important;
    border:none !important;
    color: #fff;
  }
  .btn-default{
    background: none !important;
    border:none !important;
    box-shadow:none;
    color: #fff;
    &:hover{
      background: none !important;
    }
  }
}

.search-inner .input-blue{
  &.form-control{
    background: @mycolor-blue;
  }

  &.open .dropdown-toggle.btn-default:hover{
    background: none !important;
    border:none !important;
    color: #fff;
  }
  .btn-default{
    background: none !important;
    border:none !important;
    box-shadow:none;
    color: #fff;
    &:hover{
      background: none !important;
    }
  }
}


.search-inner .input-transparent{
  &.form-control{
    background: none;
  }

  &.open .dropdown-toggle.btn-default:hover{
    background: none !important;
    border:none !important;
    color: #fff;
  }
  .btn-default{
    background: none !important;
    border:none !important;
    box-shadow:none;
    color: #fff;
    &:hover{
      background: none !important;
    }
  }
}

@media(max-width: @screen-sm){

  .shortsearchform .selectize-input, .shortsearchform .selectize-control.multi .selectize-input.has-items {
    min-width: 0;
  }

  .shortsearchform .aditional-search{
    //background: none;
    float:right;
    width:30%;
  }

}