.property-disclaimer{
  margin-top: 30px;
  font-size: 13px;
  color: #abb2cc;
}
.property-sharing{

  margin:0 auto;
}

.property-box-list-agent{
  margin-top:10px;
}
.property-box-small{
  position:relative;
  clear:both;
  margin-bottom:10px;

  .property-box-small-title{
    font-size: 14px;
    margin-bottom: 5px;
  }
  .property-box-small-image{
    position: relative;
    img{
      //position:relative;
      width:100%;
    }
  }

  .property-box-small-overlay{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    #gradient > .vertical( rgba(34,34,34,0),#222);
  }
  .property-box-price{
    text-overflow: initial;
    float:none;
    width: auto;
    overflow: visible;
    //font-size: 16px;
    padding: 0;
  }

  .property-box-small-content{
    position: absolute;
    bottom:5px;
    left:5px;
    h3{
      font-size:15px;
      margin:0;
      font-weight: bold;
      padding:0;
      color: #fff;
    }
  }
}

.at-share-btn-elements{
  text-align:center;
}
.property-amenities {
  margin: 0px 0px 30px 0px; }
.property-amenities ul {
  list-style: none;
  margin-bottom: 0px;
  padding: 0px; }
.property-amenities li {
  color: #424242;
  line-height: 34px; }
.property-amenities li.yes:before, .property-amenities li.no:before {
  color: #BDBDBD;
  content: '\f00d';
  font-family: 'FontAwesome';
  font-size: 12px;
  margin: 0px 10px 0px 0px;
  vertical-align: top; }
.property-amenities li.yes:before {
  color: #EC407A; }

.property-badge {
  background-color: #fff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  color: #757575;
  display: block;
  font-size: 11px;
  font-weight: 600;
  left: 16px;
  line-height: 1;
  padding: 7px 12px;
  position: absolute;
  text-transform: uppercase;
  top: 16px; }

.property-box {
  background-color: #fff;
 // box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
  margin: 0px 0px 30px 0px;
  position:relative;
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;
  //padding: 8px;
  border: 1px solid #EEEEEE;
  border-radius: 4px;
  overflow:hidden;


  &:hover{
    -webkit-box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.1);
  }

  h3{
    font-size: 16px;
    //color: #5277a3;
    font-weight: 600;
    text-decoration: none;
   // overflow: hidden;
   // text-overflow: ellipsis;
    //white-space: nowrap;
    margin: 5px 0 0 0;
    width: 100%;
    line-height: 150%;
    text-align:left;
    a{
      color: #424242;
      text-decoration: none;
    }
  }

  .property-box-location{
   // color: #9b9b9b;
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 13px;
    color: #777;
    margin-top:10px;

    i{
      margin-right: 5px;
     // color: @mycolor-red;
    }
  }

  .property-box-title-overlay .property-box-location{
    color: #fff;
  }


}

.property-box-header{
  padding: 5px 15px 5px;
  height: 55px;
  overflow:hidden;
  display: flex;
  justify-content: space-between;
}


.property-box-content{
  .property-box-price{

  }
}


.property-box-title-overlay-hover{
  position:absolute;
  top:0;
  left:0;
  right:0;
  bottom:50px;
  background: rgba(0,0,0,.7);
  opacity:0;
  transition: all 0.6s ease 0s;
  padding: 20px;
  h2{
    color: #fff;
    overflow: visible;
    text-overflow: clip;
    white-space: normal;
  }
  .property-box-meta-citem{
    color: #fff;
  }
}

.property-box-title-overlay{
  position:absolute;
  bottom:0;
  left:0;
  right:0;
  color: #fff;
  display: block;
  padding: 10px 15px 5px 15px;
  margin:0;
  #gradient > .vertical(rgba(0,0,0,0), #000);
  -webkit-transition: all 0.6s ease 0s;
  -o-transition: all 0.6s ease 0s;
  transition: all 0.6s ease 0s;


  .property-box-price{
    float:right;
    width: auto;
    padding:0;
    color: #fff;
    font-size: 18px;
  }

  .property-box-location{
    float:left;
    width: auto;
  }

  .property-box-meta{
    display: none;
    clear:both;
    color: #fff;
    .property-box-meta-citem{
      color: #fff;
    }
    font-size: 12px;
  }

  h2{
    color: #fff;
    display: none;
  }
}

.property-box-image {
  overflow:hidden;
  img {
    transition: all 0.7s ease;
  }
}

.property-box:hover {
//  .property-box-title-overlay-hover{
//    opacity: 1;
//
//  }

  .property-box-image {

    img {
      transform: scale(1.1);
    }

  }
  .property-box-title-overlay {
  //  background: rgba(0,0,0,.7);
//    h2{
//      display: block;
//    }
//    .property-box-meta {
//      display: block;
//    }
  }
}

.property-box-line{
  height:1px;
  position:absolute;
  bottom: 50px;
  left:0;
  right:0;
  border-top: 1px solid #EEEEEE;

}
.property-box-content {
  padding: 0 15px 30px 15px;
  //background-color: #f8f8f8;
  margin-top: 0;
  //border-top: 1px solid #cacaca;

}

.property-box-footer{
  margin-top:10px;
  display:flex;
  align-items:center;
  justify-content: space-between;
}

.property-box-image {
  background-color: rgba(0, 0, 0, 0.12);
  min-height: 150px;
  // overflow: hidden;
  position: relative;
  opacity: 1;
  transition: opacity .5s;
}

.property-box-agent{
  margin-bottom: 5px;
  position:absolute;
  bottom:0;
}
.property-box-agent-image{
 display:inline-block;
 vertical-align: top;
  margin-right: 10px;
  img {
    width:35px !important;
    height:35px !important;
    border-radius: 100%;
  }
}

.property-box-agent-info{
  display:inline-block;
  vertical-align: top;
  font-size: 12px;
  font-weight: normal;
  .property-box-agent-name{
    font-size: 12px;
    font-weight: 500;
    display: block;
    line-height: 12px;
    color: #1F1F1F;
  }
  .property-box-agent-role{
    font-size: 12px;
  }
}

.property-box-meta{
    height: 30px;
    overflow: hidden;
    margin-top: 15px;
   // margin:0 -15px -15px -15px;
    display: table;
    //width: 100%;
//  position:absolute;
 // bottom:0;
 // left:0;
 // right:0;
  position:relative;
  //border-top: 1px solid #ebebeb;

}

.property-box-meta-citem{
 // display: table-cell;
  display:inline-block;
  color: #515666;
  font-size: 10px;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  //padding:5px 10px;
  font-weight: 500;
  margin-right:15px;
 // border-right:  1px solid #ebebeb;
  span{
    display:block;
    font-size:20px;
  }
  &:last-child{
    border-right:none;
  }
  i{

    color: #BBBBBB;
    margin-right: 2px;
    //display: inline-block;
    display:none;
    width: 20px;
    line-height: 20px;
    font-size: 14px;
    &:before{
      font-size: 14px;
    }
  }
  .fa {
    opacity: .6;
  }
}

.property-box-action-post{
  float:right;
  width: 25%;
  .property-box-action-item{
    display: inline-block;
    line-height: 55px;
    text-align: center;
    cursor: pointer;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    width: 53px;
    float: right;
    border-right: 0;
    border-left: 1px solid #e5e5f8;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    color: #abb2cc;
    &:hover{
      background-color: #f9a11b;
      color: #fff;
    }
  }
}

.property-box-image:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 50px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0; }
.footer .property-box-image:before {
  color: #42A5F5; }
.property-box-image img {
  position: relative;
  //z-index: 3;
  width: 100%; }
.property-box-image:hover .property-box-excerpt {
  opacity: 1; }




.property-box-meta-item {
  float: left;
  text-align: center;
  width: 33.33%; }
.property-box-meta-item span, .property-box-meta-item strong {
  display: block; }
.property-box-meta-item span {
  color: #9E9E9E;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  opacity: .85; }
.property-box-meta-item strong {
  color: #424242;
  font-size: 22px;
  font-weight: normal;
  margin: 0px;
  opacity: .85; }

.property-box-bottom {
  background: #fbfbfe;
  border-top: 1px solid #e5e5f8;
  border-bottom: 4px solid #e5e5f8;
  overflow: hidden;
}

.property-box-price {
  color: @mycolor-green;
  font-size: 16px;
  font-weight: 700;
  //overflow: hidden;
 // text-overflow: ellipsis;
  white-space: nowrap;
  float:right;
  -webkit-transition: all 0.4s ease 0s;
  -o-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s;
  //padding: 10px 0 10px 0;
  line-height: 32px;
}

.property-box-more{
  //background: @brand-color2;
  //display: block;
  flex-shrink: 0;
  display:inline-block;
  //width: 25%;
  font-weight: 400;
  border: 1px solid @mycolor-green;
  padding: 8px 35px;
  color: @mycolor-green;
  font-size: 13px;
  text-transform: uppercase;
  border-radius: 40px;
  &:hover{
    text-decoration: none;
    background: darken(@brand-color, 10%);
    color: darkern(@brand-color, 10%);
    color: #fff;
  }
}

.property-box-view {
  color: #212121;
  float: right;
  font-size: 14px;
  text-transform: uppercase; }
.property-box-view:hover {
  color: #212121;
  text-decoration: none; }


.property-small {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin: 0px 0px 15px 0px;
  padding: 0px 0px 15px 0px; }
.footer .property-small {
  border-bottom-color: rgba(255, 255, 255, 0.12); }
.property-small:last-child {
  border: 0px;
  margin: 0px;
  padding: 0px; }

.property-small-inner {
  display: table;
  width: 100%; }

.property-small-image {
  display: table-cell;
  width: 96px; }
.property-small-image img {
  display: block;
  position: relative;
  width: 100%;
  z-index: 2; }

.property-small-image-inner {
  display: block;
  position: relative;
  background-color: rgba(0, 0, 0, 0.12);
  display: block;
  height: 60px;
  margin: 0px 16px 0px 0px;
  position: relative;
  width: 80px; }
.property-small-image-inner:after {
  background-color: black;
  background-image: url("../img/magnifier.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  content: '';
  display: table-cell;
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0px;
  transition: opacity .15s linear;
  vertical-align: middle;
  width: 100%;
  z-index: 3; }
.property-small-image-inner:hover:after {
  opacity: .5; }
.property-small-image-inner:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 24px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0; }
.footer .property-small-image-inner:before {
  color: #42A5F5; }
.property-small-image-inner:after {
  background-size: 22px 22px; }

.property-small-content {
  display: table-cell;
  vertical-align: top; }

.property-small-title {
  margin: 0px; }
.property-small-title a {
  color: rgba(0, 0, 0, 0.7);
  font-size: 15px;
  font-weight: 500; }
.footer .property-small-title a {
  color: #fff; }

.property-small-location {
  list-style: none;
  margin: 0px;
  padding: 0px; }
.property-small-location li {
  display: inline; }
.property-small-location li a {
  color: #9E9E9E;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase; }
.footer .property-small-location li a {
  color: rgba(255, 255, 255, 0.6); }
.footer .property-small-location li {
  color: rgba(255, 255, 255, 0.6); }

.property-small-price {
  color: #fff;
  display: inline-block;
  font-size: 13px;
  font-weight: 600; }

.property-simple-wrapper {
  margin-bottom: -30px; }

.property-simple {
//  margin-bottom: 30px;
}

.property-simple-image {
  display: block;
  position: relative;
  background-color: rgba(0, 0, 0, 0.15);
  display: block;
  min-height: 75px;
  position: relative; }
.property-simple-image:after {
  background-color: black;
  background-image: url("../img/magnifier.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 32px 32px;
  content: '';
  display: table-cell;
  height: 100%;
  left: 0px;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0px;
  transition: opacity .15s linear;
  vertical-align: middle;
  width: 100%;
  z-index: 3; }
.property-simple-image:hover:after {
  opacity: .5; }
.property-simple-image:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 40px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0; }
.footer .property-simple-image:before {
  color: #42A5F5; }
.property-simple-image img {
  display: block;
  position: relative;
  width: 100%;
  z-index: 2; }

.property-simple-title {
  font-size: 15px;
  margin: 15px 0px 0px 0px; }
.property-simple-title a {
  color: #232323; }

.property-simple-location {
  list-style: none;
  margin: 0px;
  padding: 0px; }
.property-simple-location li {
  display: inline; }
.property-simple-location li a {
  color: #9E9E9E;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase; }

.property-simple-status {
  color: rgba(0, 0, 0, 0.7);
  font-size: 11px;
  margin: 3px 0px 0px 0px; }
.property-simple-status strong.red {
  color: #EF5350; }
.property-simple-status strong.green {
  color: #66BB6A; }


.property-gallery-list-wrapper {
  padding: 30px 60px 14px 60px; }

.property-gallery-list-item {
  float: left;
  list-style: none;
  padding: 0px 2px;
  position: relative; }
.property-gallery-list-item a {
  background-color: rgba(0, 0, 0, 0.12);
  display: block;
  height: 50px;
  width: 50px; }
.property-gallery-list-item a img {
  display: block;
  height: 50px;
  position: relative;
  width: 50px;
  z-index: 2; }
.property-gallery-list-item a:before {
  color: rgba(0, 0, 0, 0.2);
  content: "";
  display: block;
  font-family: 'FontAwesome';
  font-size: 20px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  z-index: 0; }
.footer .property-gallery-list-item a:before {
  color: #42A5F5; }

.property-list {
  margin: 0px 0px 30px 0px; }
.property-list dl {
  font-size: 14px;
  margin: 0px;
  padding: 0px; }
.property-list dt {
  color: #616161;
  float: left;
  font-weight: normal;
  line-height: 36px; }
.property-list dd {
  border-bottom: 1px solid #EEEEEE;
  color: #424242;
  font-weight: 600;
  line-height: 36px;
  text-align: right; }
.property-list dd:last-child {
  border-bottom: 0px; }

.property-carousel-wrapper {
  background-color: #fff;
  border: 1px solid #F5F5F5;
  margin: 0px 0px 30px 0px;
  padding: 16px 50px; }

@media (max-width: 767px) {
  .property-carousel {
    width: 250px; }
  .property-carousel .property-simple-image {
    min-height: 60px; } }
.property-carousel .owl-item {
  overflow: hidden;
  width: 0px; }
.property-carousel .owl-prev, .property-carousel .owl-next {
  color: #EC407A;
  font-size: 24px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%); }
.property-carousel .owl-prev {
  left: -30px; }
.property-carousel .owl-next {
  right: -30px; }

.property-carousel-item {
  padding: 0px 10px; }
.property-carousel-item > div {
  margin-bottom: 0px; }

.properties-sort {
  background-color: #fff;
  border: 1px solid #F5F5F5;
  margin: 0px 0px 30px 0px;
  padding: 16px;
  display: block;
}

.not-found {
  margin: 120px 0px 0px 0px; }

.not-found-title {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin: 60px 0px 40px 0px;
  padding: 0px 0px 40px 0px; }
.not-found-title strong {
  color: #BDBDBD;
  font-size: 150px;
  margin-right: 20px; }
.not-found-title span {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase; }

.not-found-content h4 {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  font-size: 16px;
  font-weight: 500;
  margin: 0px 0px 20px 0px;
  padding: 0px 0px 15px 0px; }

.not-found-subtitle {
  margin: 0px 0px 40px 0px;
  padding: 0px; }

.not-found-list {
  font-size: 16px; }
.not-found-list a {
  border-bottom: 1px solid #EC407A; }
.not-found-list a:hover {
  border-bottom: 1px solid #D81B60; }
.not-found-list li {
  line-height: 36px; }

.not-found-links {
  margin: 0px; }
.not-found-links li {
  list-style: none; }
.not-found-links a {
  color: #424242; }
.not-found-links a:hover {
  text-decoration: underline; }


.property-box-meta:before, .property-box-bottom:before,.property-box-meta:after, .property-box-bottom:after {
  display:table;
  content:" ";
}

.property-box-meta:after, .property-box-bottom:after, {
  clear: both;
}

.property-box-title{
  margin-top:5px;
  height: 42px;
  overflow: hidden;
}


.label-wrapper{
  position:absolute;
  top:10px;
  left:15px;
  z-index: 10;
  &.label-right{
    right:15px;
    left:auto;
  }
}


.label{
  display: inline-block;
  margin-right: 5px;
  color: #fff;
 // border-radius: 2px;
  font-size: 10px;
  padding: 5px 10px 5px;
  text-transform: uppercase;
  margin-bottom: 0;
  line-height: 11px;
  vertical-align: top;
  font-weight: 500;
  z-index: 3;
  border-radius: 30px;
  margin-bottom: 5px;
}

.label-transaction{
  background-color: @mycolor-green;

}

.label-top{
  background-color: @mycolor-yellow;
}

.label-status2, .label-status3, .label-status4 {
  //background-color: @mycolor-red;
  background-color: @mycolor-yellow;
}

.label-featured{
  background-color:@brand-color;
}

.label-commision{
  background-color:@brand-color;
}


.properties-sort-info{
  float:left;
}

.properties-sort-form{
  float:right;
}

.property-box .label, .property-box-list .label{
  display:block;
}

@media (max-width: 767px) {
  .property-box h2{
    overflow: visible;
    white-space: normal;
    font-size: 16px;
  }
}


.propertyspecial{
  margin:0;

  .col-property{
    padding:0;
    .property-box{
      padding:0;
      margin-bottom:0;
    }

  }
}

.favorite-icon{
  color: #fff;
  z-index: 100;
  text-shadow: 0 0 3px rgba(0,0,0,.3);
  &.active{
    .on{
      display: inline-block;
      color: red;
    }
    .off{
      display: none;
    }
  }
  .on{
    display:none;
  }
  i{
    font-size: 30px;
  }
}

.viewwrapper{
  margin-right: 15px;

  .btn-default{
    background:none;

    &.active{
      background: #EDF1F2;

    }
  }
}


.col-property-list{
  position:relative;
  //padding: 10px;

  margin-bottom: 20px;
  //background: #fff;
  padding-right: 15px;
  padding-left: 15px;

  &:after{
    content:' ';
    clear:both;
  }



}

.property-box-list{
  background: #fff;
  border: 1px solid #EEEEEE;
  &:hover{
    -webkit-box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 30px 3px rgba(0, 0, 0, 0.1);
  }
  position:relative;
}

.property-box-list-content{
  margin-top:15px;
}

.more-details{
  position:absolute;
  bottom:0;
  right:0;
  display:block;
  background: @brand-color;
  color:#fff;
  padding: 5px 15px;
  text-align: center;
  font-size: 13px;
 // border-radius: 5px;
  &:hover{
    color: #fff;
    text-decoration: none;
    background: darken(@brand-color,10%);
  }
}

.property-box-list-contentwrapper{

 // min-height: 260px;
  padding:5px 10px 10px 0;
  position:relative;


  h2{
    font-size: 16px;
    font-weight: 400;
    line-height: 1.25;
    color: #424242;
    margin:5px 0;
    a{
      color: #424242;
      &:hover{
        text-decoration:none;
      }
    }
  }

  .property-box-list-meta{
    margin-bottom: 10px;
  }

  .property-box-list-meta-citem{
    display: inline-block;
    color: #515666;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: #EDF1F2;
    padding:5px 10px;
    i{
      color: #BBBBBB;
      margin-right: 2px;
      display: inline-block;
      width: 20px;
      line-height: 20px;
      font-size: 14px;
      &:before{
        font-size: 14px;
      }
    }
    .fa {
      opacity: .6;
    }
  }


  .property-box-list-footer{
   // position:absolute;
   // bottom:0;
   // left: 0;
   // width: 100%;
  }

  .property-box-list-more{
    display: block;
    float:right;
    background: @brand-color2;
    color: #fff;
    padding: 5px 25px;
    font-size: 15px;
    text-transform: uppercase;
    border-radius: 3px;
    &:hover{
      background: darken(@brand-color2, 10%);
      text-decoration: none;
    }
  }
  .property-box-list-price{

    color: @brand-color;
    font-weight:bold;
    font-size: 22px;
    float:right;
  }

}
.property-box-list-image{
  position:relative;
  img{
    width: 100%;
  }
}

.property-box-list-description{
  font-size: 14px;
  height: 75px;
  overflow: hidden;
  margin-top: 10px;
  opacity: .7;
}